import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Spinner from "./Components/spinner";
import ScrollToTop from "./Components/scrollToTop";
import PaymentSuccess from "./Pages/paymentSuccess";

const Wrapper = lazy(() => import("./Layouts/wrapper"));
const Protected = lazy(() => import("./Layouts/protected"));
const Career = lazy(() => import("./Pages/career/career"));
const MyProfile = lazy(() => import("./Pages/myProfile"));
const EditProfile = lazy(() => import("./Pages/editProfile"));
const ViewJob = lazy(() => import("./Pages/career/viewJob"));
const CategoryJobs = lazy(() => import("./Pages/career/categoryJobs"));
const Learning = lazy(() => import("./Pages/learning/dwLearning"));
const Courses = lazy(() => import("./Pages/learning/courses"));
const CourseDetails = lazy(() => import("./Pages/learning/courseDetails"));
const CoursePlay = lazy(() => import("./Pages/learning/coursePlay"));
const Discussion = lazy(() => import("./Pages/discussions/discussion"));
const ViewQna = lazy(() => import("./Pages/discussions/viewQna"));
const Social = lazy(() => import("./Pages/social/social"));
const CreatePost = lazy(() => import("./Pages/social/createPost"));
const CaseDiscussion = lazy(() => import("./Pages/social/caseDiscussion"));
const PostJob = lazy(() => import("./Pages/career/postJob"));
const Applicants = lazy(() => import("./Pages/career/applicants"));
const EditJob = lazy(() => import("./Pages/career/editJob"));

const Mentor = lazy(() => import("./Pages/mentor/mentor"));
const Allmentors = lazy(() => import("./Pages/mentor/allmentors"));
const MentorDetails = lazy(() => import("./Pages/mentor/mentorDetails"));
const CareerMentor = lazy(() => import("./Pages/mentor/careerMentor"));
const LandingLayout = lazy(() => import("./Layouts/landingLayout"));
const Home = lazy(() => import("./Pages/home"));
const SignIn = lazy(() => import("./Pages/signIn"));
const SignUp = lazy(() => import("./Pages/signUp"));
const ForgetPassword = lazy(() => import("./Pages/forgetPassword"));
const TermsAndConditions = lazy(() => import("./Pages/termsAndConditions"));
const BecomeMentor = lazy(() => import("./Pages/mentor/becomeMentor"));
const Error = lazy(() => import("./Pages/error"));
const PrivacyPolicy = lazy(() => import("./Pages/privacyPolicy"));
const Contact = lazy(() => import("./Pages/contact"));

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        }
      >
        <ScrollToTop>
          <Routes>
            <Route element={<Wrapper />}>
              <Route path={"/career"} element={<Protected Cmp={Career} />} />
              <Route
                path={"/profile"}
                element={<Protected Cmp={MyProfile} />}
              />
              <Route
                path={"/editProfile"}
                element={<Protected Cmp={EditProfile} />}
              />
              <Route
                path={"/career/:jobid"}
                element={<Protected Cmp={ViewJob} />}
              />
              <Route
                path={"/category/:name"}
                element={<Protected Cmp={CategoryJobs} />}
              />
              <Route path={"/learn"} element={<Protected Cmp={Learning} />} />
              <Route path={"/courses"} element={<Protected Cmp={Courses} />} />
              <Route
                path={"/course/details"}
                element={<Protected Cmp={CourseDetails} />}
              />
              <Route
                path={"/courseplay"}
                element={<Protected Cmp={CoursePlay} />}
              />
              <Route
                path={"/discussion"}
                element={<Protected Cmp={Discussion} />}
              />
              <Route
                path={"/discussion/:qid"}
                element={<Protected Cmp={ViewQna} />}
              />
              <Route path={"/home"} element={<Protected Cmp={Social} />} />
              <Route
                path={"/create"}
                element={<Protected Cmp={CreatePost} />}
              />
              <Route
                path={"/caseDiscussion"}
                element={<Protected Cmp={CaseDiscussion} />}
              />
              <Route path={"/postJob"} element={<Protected Cmp={PostJob} />} />
              <Route
                path={"/edit-job/:jobId"}
                element={<Protected Cmp={EditJob} />}
              />

              <Route
                path={"/career/applicants/:jobId"}
                element={<Protected Cmp={Applicants} />}
              />

              <Route path={"/mentor"} element={<Protected Cmp={Mentor} />} />
              <Route
                path={"/all-mentor/:id"}
                element={<Protected Cmp={Allmentors} />}
              />
              <Route
                path={"/career-mentor/:id"}
                element={<Protected Cmp={CareerMentor} />}
              />
              <Route
                path={"/mentor/:id"}
                element={<Protected Cmp={MentorDetails} />}
              />
              <Route
                path={"/be-a-mentor"}
                element={<Protected Cmp={BecomeMentor} />}
              />
              <Route
                path={"/success"}
                element={<Protected Cmp={PaymentSuccess} />}
              />
            </Route>
            <Route element={<LandingLayout />}>
              <Route path={"/"} element={<Home />} />
              <Route path={"/signin"} element={<SignIn />} />
              <Route path={"/signup"} element={<SignUp />} />
              <Route path={"/forgetpassword"} element={<ForgetPassword />} />
              <Route path={"/privacypolicy"} element={<PrivacyPolicy />} />
              <Route path={"/contact"} element={<Contact />} />
              <Route path={"*"} element={<Error />} />

              <Route
                path={"/termsandcondition"}
                element={<TermsAndConditions />}
              />
            </Route>
          </Routes>
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
